import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  autocompleteContainerCX,
  headerNavMobileContainerCX,
  headerNavMobileLogoCX,
} from 'app/shared/modules/navigation/header/HeaderNavMobileComponentStyles';
import HeaderNavMobileLogo from 'app/shared/modules/navigation/header/HeaderNavMobileLogo';
import AutocompleteSearchInput from 'app/shared/modules/location/AutocompleteSearchInput';
import LoginStatus from 'app/shared/modules/LoginStatus';
import routeUtils from 'app/shared/utils/routeUtils';

interface HeaderNavMobileProps {
  onOpenHamburgerNav: () => void;
  onCloseHamburgerNav: () => void;
  unreadMsgCount?: number;
}

const HeaderNavMobile: React.FC<HeaderNavMobileProps> = ({
  onOpenHamburgerNav,
  onCloseHamburgerNav,
  unreadMsgCount = 0,
}) => {
  const location = useLocation();

  const pathname = location.pathname;
  const isAreaUrlOrNearMe = routeUtils.isAreaUrl(pathname) || routeUtils.isNearMeUrl(pathname);
  const isFrontPage = pathname === '/';

  const renderAutocompleteSearch = ({ showRecentSearchItem }: { showRecentSearchItem: boolean }) => (
    <AutocompleteSearchInput
      includeCancel
      includeClear
      full
      showRecentSearchItem={showRecentSearchItem}
      useSmallScreenWidthDropdown
    />
  );

  return (
    <nav className={headerNavMobileContainerCX} data-name="HeaderNavMobileContainer">
      <HeaderNavMobileLogo
        className={headerNavMobileLogoCX}
        logoSize={isFrontPage ? 'full' : null}
        onCloseHamburgerNav={onCloseHamburgerNav}
        onClick={onOpenHamburgerNav}
        unreadMsgCount={unreadMsgCount}
      />
      {isAreaUrlOrNearMe ? (
        <div className={autocompleteContainerCX} data-name="AutocompleteContainer">
          {renderAutocompleteSearch({ showRecentSearchItem: false })}
        </div>
      ) : (
        <LoginStatus />
      )}
    </nav>
  );
};

export default HeaderNavMobile;
